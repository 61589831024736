import React from "react"
import SillySiteChallengeLogo from "../SillySiteChallengeLogo"
import PullBelowHeader from "../../../containers/pull-below-header"

const headerCss = {
  margin: `0 auto`,
}

const logoCss = theme => ({
  padding: `0 ${theme.space[7]}`,
  width: `100%`,

  [theme.mediaQueries.desktop]: {
    maxHeight: `312px`,
    padding: "unset",
  },
})

export default () => (
  <PullBelowHeader>
    <div css={headerCss}>
      <SillySiteChallengeLogo css={logoCss} />
    </div>
  </PullBelowHeader>
)
