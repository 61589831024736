import React from "react"
import {
  CustomPageLayout,
  ContentRenderer,
  ComponentsProvider,
} from "../CustomPageLayout"
import { allSillySiteFaqComponents } from "./components"

export function SillySiteFAQPage({ seo, location, content }) {
  return (
    <CustomPageLayout invertHeader seo={seo} location={location}>
      <ComponentsProvider value={allSillySiteFaqComponents}>
        <ContentRenderer content={content} />
      </ComponentsProvider>
    </CustomPageLayout>
  )
}
