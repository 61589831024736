// THIS FILE SHOULD BE TREATED AS DEPRECATED
// Use theme from gatsby-interface instead of directly importing tokens from this file
import { getTheme } from "gatsby-interface"
import breakpoints from "./breakpoints"
import colors from "./colors"
import mediaQueries from "./media-queries"

const theme = getTheme()

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const fontSizes = theme.fontSizes

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const fontWeights = theme.fontWeights

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const fonts = theme.fonts

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const letterSpacings = theme.letterSpacings

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const lineHeights = theme.lineHeights

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const radii = theme.radii

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const shadows = theme.shadows

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const space = theme.space

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const transition = theme.transitions

export {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  mediaQueries,
  radii,
  shadows,
  space,
  transition,
}
