import { graphql } from "gatsby"
import { SillySiteFAQPage } from "../../components/SillySiteFAQPage"
import { withData } from "../../components/CustomPageLayout"

function CustomPageSillySiteFAQTemplate(props) {
  return withData(props)(SillySiteFAQPage)
}

export default CustomPageSillySiteFAQTemplate

export const pageQuery = graphql`
  query CustomPageSillySiteTemplateFAQQuery($id: String!) {
    contentfulCustomPage(id: { eq: $id }) {
      ...CustomPage
    }
  }
`
