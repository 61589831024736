import breakpoints from "./breakpoints"

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
const mediaQueries = {}
for (const breakpoint in breakpoints) {
  if (breakpoints.hasOwnProperty(breakpoint)) {
    mediaQueries[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]})`
  }
}

export default mediaQueries
