import React from "react"
import { Markdown } from "./"

const formatJudgingCriterias = (rawCriterias = []) => {
  return rawCriterias.map(rawCriteria => {
    const icon = rawCriteria.content.find(
      ({ __typename }) => __typename === "ContentfulPicture"
    )

    const html = rawCriteria.content.find(
      ({ __typename }) => __typename === "ContentfulMarkdown"
    )

    return {
      html: html?.body?.childMarkdownRemark?.html,
      icon: icon?.image?.file?.url,
    }
  })
}

const columnsCss = theme => ({
  display: "flex",
  flexDirection: "column",

  marginBottom: theme.space[8],

  "&:last-of-type": {
    marginBottom: 0,
  },

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
})

const leftSideCss = theme => ({
  paddingRight: theme.space[8],
  height: "64px",
  marginTop: theme.space[7],

  [theme.mediaQueries.tablet]: {
    height: "36px",
    marginTop: "unset",
  },
})

const rightSideCss = {
  flex: 1,
}

export const JudgingCriterias = props => {
  const judgingCriterias = formatJudgingCriterias(props.data.content)

  return (
    <div>
      {judgingCriterias.map((judgingCriteria, index) => (
        <div css={columnsCss} key={`juding-criteria-${index}`}>
          <img
            src={judgingCriteria.icon}
            alt=""
            aria-hidden={true}
            css={leftSideCss}
          />

          <div css={rightSideCss}>
            <Markdown html={judgingCriteria.html} />
          </div>
        </div>
      ))}
    </div>
  )
}
