import gray from "gray-percentage"
import { getTheme } from "gatsby-interface"

const { colors } = getTheme()

/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
export default {
  ...colors,
  night: colors.purple[90],
  ui: {
    bright: colors.purple[20],
    light: colors.purple[10],
  },
  gray: {
    dark: gray(8, 270),
    copy: gray(12, 270),
    neutral: gray(28, 270),
    calm: gray(46, 270),
    bright: gray(76, 270),
    light: gray(86, 270),
    faint: gray(96, 270),
  },
  react: {
    dark: `#1c3e47`,
  },
}
