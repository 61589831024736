/**
 * @deprecated Use theme from gatsby-interface instead of directly importing tokens
 */
export default {
  mobile: `400px`,
  phablet: `550px`,
  tablet: `750px`,
  desktop: `1000px`,
  hd: `1200px`,
  hhd: `1600px`,
}
