import React from "react"
import SillySiteHeader from "../SillySiteHeader"
import { ContentRenderer, normalizeContent } from "../../../CustomPageLayout"
import PullBelowHeader from "../../../containers/pull-below-header"
import bigPlusSvg from "./icons/BigPlus.svg"
import codeSvg from "./icons/Code.svg"
import halfCircleSvg from "./icons/HalfCircle.svg"
import notFilledCircleSvg from "./icons/NotFilledCircle.svg"
import tinyFilledCircleSvg from "./icons/TinyFilledCircle.svg"
import tinyPlusSvg from "./icons/TinyPlus.svg"

const pageContainerCss = theme => ({
  backgroundColor: theme.colors.purple[90],
  color: theme.colors.white,
  paddingBottom: theme.space[10],

  [theme.mediaQueries.tablet]: {
    paddingTop: `20rem`,
    paddingBottom: theme.space[15],
  },
})

const sectionCss = theme => ({
  maxWidth: "280px",
  margin: `0 auto`,

  [theme.mediaQueries.phablet]: {
    maxWidth: "500px",
  },

  [theme.mediaQueries.desktop]: {
    maxWidth: "768px",
  },
})

const sharedIconCss = theme => ({
  display: "none",
  [theme.mediaQueries.tablet]: {
    position: "absolute",
    display: "block",
  },
})

const PageSectionContainer = ({ children }) => {
  return (
    <div css={pageContainerCss}>
      <img
        src={bigPlusSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { left: 0, top: "75%" }]}
      />
      <img
        src={codeSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { right: "10%", top: "25%" }]}
      />
      <img
        src={halfCircleSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { right: 0, top: "50%" }]}
      />
      <img
        src={notFilledCircleSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { left: 0, top: "40%" }]}
      />
      <img
        src={tinyFilledCircleSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { left: "20%", marginTop: "10%" }]}
      />
      <img
        src={tinyPlusSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { right: 0, top: "65%" }]}
      />

      <img
        src={tinyFilledCircleSvg}
        alt=""
        aria-hidden={true}
        css={theme => [sharedIconCss(theme), { right: "20%", top: "90%" }]}
      />

      {children}
    </div>
  )
}

export default function PageSection({ data }) {
  const content = normalizeContent(data.content)

  return (
    <PullBelowHeader>
      <PageSectionContainer css={pageContainerCss}>
        <SillySiteHeader />
        <div css={sectionCss}>
          <ContentRenderer content={content} />
        </div>
      </PageSectionContainer>
    </PullBelowHeader>
  )
}
