import React from "react"
import PageSection from "./PageSection"
import { Heading as GIHeading } from "gatsby-interface"
import { JudgingCriterias } from "./JudgingCriterias"
import withHeadingData from "../../CustomPageLayout/components/Heading.withData"
import withMarkdownData from "../../CustomPageLayout/components/Markdown.withData"
import slugify from "slugify"

const headingCss = theme => ({
  position: `relative`,
  color: theme.colors.white,
  fontSize: theme.fontSizes[10],
  marginTop: theme.space[12],
  marginBottom: theme.space[10],
})

const Heading = ({
  htmlText,
  visuallyHidden: _vh,
  tag,
  componentName: _,
  ...rest
}) => (
  <GIHeading
    id={slugify(htmlText).toLowerCase()}
    dangerouslySetInnerHTML={{ __html: htmlText }}
    as={tag}
    {...rest}
    css={headingCss}
  />
)

const markdownCss = theme => ({
  color: theme.colors.white,
  margin: 0,

  "h2,h3,h4,h5,h6": {
    "&:first-of-type": {
      marginTop: 0,
    },

    color: theme.colors.white,
    marginTop: theme.space[9],
    marginBottom: theme.space[7],
  },

  a: {
    color: theme.colors.purple[30],
  },

  ol: {
    marginTop: theme.space[5],
    marginBottom: theme.space[5],
  },

  "p:last-of-type": {
    marginBottom: 0,
  },
})

export const Markdown = ({ html, ...rest }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      css={markdownCss}
      {...rest}
    />
  )
}

export const allSillySiteFaqComponents = {
  PageSection,
  Heading: withHeadingData(Heading),
  Markdown: withMarkdownData(Markdown),
  JudgingCriterias,
}
